.logo {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {
  .logo {
    width: 179px;
  }
}
