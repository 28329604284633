.banner {
  background-color: #ffd085;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  height: 100%;
  flex: 1;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 0.875em;
  color: #3a2503;
  font-weight: 600;
}

.banner:hover {
  background-color: #ffc262;
}

@media only screen and (max-width: 767px) {
  .banner-text {
    display: none;
  }

  .banner-content svg {
    width: 20px;
    height: 20px;
  }

  .hidden {
    display: none;
  }

  .banner {
    height: 30px;
  }

  .banner-content {
    font-size: 0.75em;
  }
}

@media only screen and (min-width: 767px) {
  .banner-text-mobile {
    display: none;
  }
  
}