.header {
  position: relative;
  width: 100%;
  height: 70px;
  background: #ffffff;
  color: #343a40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.header h1 {
  font-size: 1.5em;
}

.header__logo {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 22px;
  position: relative;
  z-index: 2;
}

.logo {
  display: flex;
  align-items: center;
}

.GitHubRibbon {
  fill: #748ffc;
  color: #ffffff;
  position: absolute;
  top: 0px;
  border: 0px;
  left: 0px;
  z-index: 2;
  transform: scaleX(-1);
}

.header__right-content {
  display: flex;
  gap: 24px;
  position: absolute;
  right: 0;
  margin-right: 1.5em;
}

.donate-link {
  text-decoration: none;
}

.donate-button {
  background-color: #0067EB;
  color: white;
  border: none;
  padding: 8px 14px;
  font-family: "Fredoka", Arial, Helvetica, sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  outline: none;
  transition: background-color 0.3s;
}

.donate-button:hover {
  background-color: #005CD1;
}


.social-icons {
  right: 2em;
  display: flex;
  align-items: center;
  gap: 18px;
}

.social-icons a {
  box-sizing: border-box;
  cursor: pointer;
}

.social-icons span {
  font-family: "Fredoka", Arial, Helvetica, sans-serif;
  font-size: 0.875em;
  color: #495057;
  font-weight: 500;
}

.twitter-ic:hover svg path {
  fill: #1da1f2;
}

.fb-ic:hover svg path {
  fill: #4267b2;
}

.linkedin-ic:hover svg path {
  fill: #0077b5;
}

.logo {
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {

  .header__right-content {
    margin-right: 1em;
  }

  .social-icons {
    display: none;
  }

  .GitHubRibbon svg {
    display: none;
  }

  .header {
    height: 50px;
  }

  .logo {
    width: 179px;
  }

  .donate-button {
    border-radius: 8px;
    font-size: .65em;
    gap: 4px;
    padding: 2px 6px;
    line-height: 20px;
  }

  .donate-button svg {
    width: 12px;
    height: 12px;
  }
  
}

@media only screen and (max-width: 1024px) {
  .social-icons {
    display: none;
  }
}